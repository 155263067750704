.Head {
  background-color: #282c34;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  color: white
}

.Search {
  margin: 10px;
  display: flex;
  justify-content: center;
  padding: 10px;
}
