.Login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    align-items: center;
    /*background: linear-gradient(to bottom, black 0%, #0060f7 100%);*/
}

.Header {
    display: flex;
    justify-content: center;
    width: 55vw;
    height: 22vh;
    background: white;
}

.Fields {
    width: 55vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: auto;
    background: white;
}

.Text {
    font-weight: bold;
}

.Inputs {
   margin-bottom: 15px; 
}

.Error {
    color: red;
    margin-bottom: 10px;
}
